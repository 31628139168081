


//Lib


// Utils
@import "./utils/reset";
@import "./utils/sizes";
@import "./utils/mixins";
@import "./utils/_settings";
@import "./utils/_color";
@import "./utils/mui";

// Layouts
@import "../layouts/drawer-nav/drawernav";


// Components
@import "../components/card_user_minimal/carduserminal.scss";
@import "../components/card_stats_number/cardstatsnumber.scss";
@import "../components/card_user/card-user.scss";
@import "../components/message/message.scss";
@import "../components/entreprise/billing/billing.scss";
@import "../components/entreprise/general/general.scss";
@import "../components/entreprise/notifications-settings/notification-setting.scss";
@import "../components/entreprise/security/security.scss";
@import "../components/stepper/firststep/first-steps.scss";
@import "../components/stepper/secondstep/second-steps.scss";
@import "../components/stepper/finalstep/final-step.scss";
@import "./component/modal/modal.scss";

// Pages
@import "../views/login/login.scss";
@import "../views/dashboard/dashboard";
@import "../views/analytics/analytics.scss";
@import "../views/card-design/card-design.scss";
@import "../views/chat/chat.scss";
@import "../views/enterprise/enterprise.scss";
@import "../views/file-manager/filemanager.scss";
@import "../views/profil/profile.scss";
@import "../views/settings/settings.scss";
@import "../views/shop/shop.scss";
@import "../views/user/list-users/list-users.scss";
@import "../views/user/card-users/card-users.scss";
@import "../views/redirect-count/redirect-count.scss";



#app {
  height: 100%;
}
body {
  background: #f0eef2;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}









