.component--second-step{
  display:flex;
  flex-direction:column;
  padding:10px;
  .title-step{
    font-size:16px;
    margin-bottom:10px;
  }
  .description{
    font-size:14px;
    margin-bottom:20px;
  }

}