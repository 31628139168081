.view--card-design{
  display: flex;
  flex-direction: column;
  padding: 100px 50px;
  max-width: 1400px;
  margin: 0 auto;
  .title-card-design{
    font-size: 20px;
    color: black;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .container-card-design{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .container-image-dededed{
      width: calc(50% - 50px);
      position: relative;
      img{
        width: 100%;
        height: 600px;
        position: absolute;
      }
      .card-exemple{
        position: absolute;
        left: 45%;
        top: 190px;
        width: 340px;
        height: 205px;
        border-radius: 30px;
        transform: translateX(-45%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        .element{
          font-size: 12px;
          margin-bottom: 5px;
          color: #FFFFFF;
        }
      }
    }
    .right-part{
      width: calc(50% - 50px);
    }
  }
}