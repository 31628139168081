.component--security{
  display: flex;
  flex-direction: column;
  padding: 20px;
  .container-card-security{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    width: 100%;
    .title-card{
      font-size: 16px;
      font-weight: 500;
      width: calc(30% - 50px);
    }
    .container-card-input{
      width: calc(70% - 50px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      .button-save{
        width: calc(20% - 10px);
        cursor: pointer;
        text-align: center;
        color: #22286F;
        padding: 10px;
        &:hover{
          background: rgba(34, 40, 111,0.2);
          border-radius: 10px;
        }
      }
    }
  }
}