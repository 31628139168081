.view--card-users{
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  .title-users{
    font-size: 1.5rem;
    color: black;
    margin-left: 40px;
    font-weight: 600;
    margin-bottom: 80px;
  }
  .container-card-users{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}