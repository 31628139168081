.component--billing{
  display: flex;
  flex-direction: column;
  .title-explain-billing{
    font-size: 1.0417rem;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .subtitle-explain-billing{
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.57;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    color: rgb(108, 115, 127);
    margin-bottom: 60px;
  }
  .container-billing-plan{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    .container-plan{
      cursor: pointer;
      border-radius: 10px;
      width: calc(33% - 30px);
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      img{
        width: 30px;
        margin-bottom: 20px;
      }
      .title-price{
        font-size: 1.4583rem;
        color: black;
        font-weight: 600;
        margin-bottom: 10px;
        span{
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.57;
          font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
          color: rgb(108, 115, 127);
        }
      }
      .title-plan{
        margin: 0px;
        font-size: 0.75rem;
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: 2.5;
        text-transform: uppercase;
        font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
      }
      &.selected{
        border: 1px solid #22286F;
      }
    }
  }
  .container-change-header-billing{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-change{
      font-size: 1.0417rem;
      font-weight: 600;
      margin-bottom: 5px;
    }
    .button-edit{
      padding: 20px;
      display: flex;
      align-items: center;
      .title-edit{
        font-size: 15px;
        font-weight: 600;
        color: #22286F;
      }
    }
  }
  .container-input-change{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    .title-input{
      color: gray;
      border: 0.5px solid gray;
      padding: 5px 5px;
      span{
        margin-left: 30px;
      }
    }
    .border-top{
      border-radius: 5px 5px 0 0;
    }
    .border-bottom{
      border-radius: 0px 0px 5px 5px;
    }
  }
  .button-upgrated-plan{
    position: relative;
    left: 100%;
    max-width: 200px;
    padding: 10px;
    transform: translateX(-100%);
    border-radius: 10px;
    background: #22286F;
    color: #FFFFFF;
    text-align: center;
    font-size: 16px;
  }
}