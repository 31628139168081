.messageRow{
  display: flex;
};

.messageRowRight {
  display: flex;
  justify-content: flex-end;
}
.messageBlue{
  position: relative;
  margin-left: 20px;
  margin-bottom: 10px;
  padding: 12px;
  background: #A8DDFD;
  width: 40%;
  text-align: left;
  font: 400 0.9em "Open Sans";
  border: 1px solid #97C6E3;
  border-radius: 10px;
  &:after{
    content: "''";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px solid #A8DDFD;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    top: 0;
    left: -15px;
  };
  &:before{
    content: "''";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 17px solid #97C6E3;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    top: -1px;
    left: -17px;
  }
};
.messageOrange{
  position: relative;
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 10px;
  background: #f8e896;
  width: 60%;
  //height: "50px";
  text-align: left;
  font: 400 .9em 'Open Sans';
  border: 1px solid #dfd087;
  border-radius: 10px;
  &:after{
    content: "''";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px solid #f8e896;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    top: 0;
    right: -15px;
  };
  &:before{
    content: "''";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 17px solid #dfd087;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    top: -1px;
    right: -17px;
  }
};

.messageContent{
  padding: 0;
  margin: 0;
  word-wrap: break-word;

};
.messageTimeStampRight{
  position: absolute;
  font-size: .85em;
  font-weight: 300;
  margin-top: 10px;
  bottom: 2px;
  right: 5px;
};

.orange{
  color: #FFFF00;
  background: #FFFF00;
  width: 200px;
  height: 150px;
};
.avatarNothing{
  color: transparent;
  background: transparent;
  width: 40px;
  height: 40px;
};
.displayName{
  margin-left: 20px;
  color: #FFFFFF;
}
