.view--redirect{
  width: 100%;
  height: 100vh;
  .redirect-modal{
    position: relative;
    left: 50%;
    top: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translate(-50%,-50%);
    background: rgba(0,0,0,0.3);
    border-radius: 10px;
    width: 300px;
    height: 300px;
    padding: 10px;
    .title{
      font-size: 12px;
      text-align: center;
      line-height: 10px;
      margin-bottom: 20px;
    }
    .button-container{
      display: flex;
      align-items: center;
      position: relative;
      margin-top: 54px;
      left: 20px;

      .button{
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: rgba(34, 10, 110,0.15);
        height: 30px;
        width: 100px;
        text-align: center;
        font-size: 10px;
        color: white;
        cursor: pointer;
        margin-right: 40px;
        border-radius: 3px;
      }
    }
  }
}