.component--notification-setting{
  display: flex;
  flex-direction: column;
  padding: 20px;
  .container-card {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 30px;

    .title-card {
      width: calc(30% - 60px);
      font-size: 20px;
      color: #000000;
      font-weight: 500;
    }

    .left-part {
      width: calc(70% - 60px);
      display: flex;
      flex-direction: column;
      .hr{
        height: 1px;
        width: 100%;
      }
      .container-left-part{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .content-container{
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          .content-title{
            font-size: 16px;
            color: #000000;
            font-weight: 500;
            margin-bottom: 5px;
          }
          .content-subtitle{
            font-size: 14px;
            color: gray;
            font-weight: 400;
          }
        }
      }
    }
  }
}