.card-user-component{
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  display:flex;
  flex-direction: column;
  align-items: center;
  width:calc(33% - 30px);
  margin-right: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
  &:nth-child(3n + 3){
    margin-right: 0;
  }
  .img-user-profile{
    border-radius: 50%;
    width: 80px;
    height: 80px;
    background: #212b36;
    margin-bottom: 20px;
  }
  .title-name{
    margin-top: 20px;
    font-weight: 600;
    line-height: 1.5;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  .subtitle-job{
    line-height: 1.57143;
    font-size: 0.875rem;
    font-weight: 400;
    color: rgb(99, 115, 129);
  }
  .container-type-card{
    display: flex;
    align-items: center;
    margin-top: 30px;
    .logo-card-poss{
      text-decoration: none;
      width: 40px;
      border-radius: 50%;
      margin-right: -10px;
    }
  }
  .liner{
    margin-top: 30px;
    width: 100%;
    height: 1px;
    background: #d1e7dd;
  }
  .global-container-stats{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 10px;
    .container-stats{
      display: flex;
      width: calc(33% - 50px);
      flex-direction: column;
      align-items: center;
      .title-stats{
        line-height: 1.5;
        font-size: 0.75rem;
        font-weight: 400;
        margin-bottom: 10px;
        color: rgb(145, 158, 171);
      }
      .number-stats{
        font-weight: 600;
        line-height: 1.5;
        font-size: 1rem;
        color: black;
      }
    }
  }
}