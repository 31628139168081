.view-dashboard{
  display: flex;
  flex-direction: column;
  padding: 10px;
  .title-header{
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .container-dashboard{
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.marginTop{
      margin-top: 15px;
    }
  }
}