.component--general-entreprise{
  display: flex;
  flex-direction: column;
  padding: 20px;
  .container-card{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 30px;
    .title-card-container{
      display: flex;
      flex-direction: column;
      .title-card{
        width: calc(30% - 60px);
        font-size: 20px;
        color: #000000;
        font-weight: 500;
      }
      .file-drop-area{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .circle{
          background: black;
          border-radius: 50%;
          width: 100px;
          height: 100px;
          z-index: 100;
        }
        input[type=file] {
          display: none;
        }
        .save{
          margin-top: 40px;
          cursor: pointer;
          width: 100%;
          color: #FFFFFF;
          padding: 10px;
          text-align: center;
          background: #212b36;
          border-radius: 10px;
        }
      }
    }

    .left-part{
      width: calc(70% - 60px);
      display: flex;
      flex-direction: column;
      .title-upload{
        margin-bottom: 20px;
        font-size: 16px;
        color: black;
      }
      .file-drop-area {
        position: relative;
        display: flex;
        align-items: center;
        max-width: 100%;
        padding: 25px;
        border: 1px dashed rgba(34, 10, 110, 0.4);
        border-radius: 3px;
        transition: 0.2s;
        &.is-active {
          background-color: rgba(34, 10, 110, 0.05);
        }
      }

      .fake-btn {
        flex-shrink: 0;
        background-color: rgba(34, 10, 110, 0.04);
        border: 1px solid rgba(34, 10, 110, 0.1);
        border-radius: 3px;
        padding: 8px 15px;
        margin-right: 10px;
        font-size: 12px;
        text-transform: uppercase;
      }

      .file-msg {
        font-size: small;
        font-weight: 300;
        line-height: 1.4;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .file-input {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
        opacity: 0;
        &:focus {
          outline: none;
        }
      }
      .content{
        font-size: 16px;
        color: #000000;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .button-save{
        border-radius: 10px;
        color: white;
        text-align: center;
        position: relative;
        left: 100%;
        transform: translateX(-100%);
        padding: 10px;
        width: 200px;
        cursor: pointer;
        border: 1px solid #22286F;
        background: #22286F;
      }
      .button-delete{
        border-radius: 20px;
        text-align: center;
        padding: 10px;
        width: 200px;
        cursor: pointer;
        border: 1px solid #ff0000;
        background: #ff0000;
        color: #FFFFFF;
      }
    }
  }
}