.view-login{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px 0 50px 50px;
  z-index: 1;
  height: 100vh;
  .app-logo{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-bottom: 50px;
  }
  .body{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &.exptB{
      height: 100%;
      padding: 0 20px;
    }
    .left-part{
      width: calc(70% - 20px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top:100px;
      &.exptl{
        margin-top:10px;
        width: calc(50% - 20px);
      }
      .title{
        font-size: 2rem;
        margin-bottom: 100px;
        color: black;

      }
      .login-img{
        width: 874px;
        height: 551px;
      }
    }
    .right-part{

      height: 1500px;
      z-index: 100;
      position: relative;
      top: -200px;
      background-color: #FFF;
      width: calc(30% - 20px);
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 250px 20px;
      &.exptr{
        width: calc(50% - 20px);
        height: 100%;
        position: relative;
        border-radius: 20px;
        padding: 50px 20px;
        top: 0;
      }
      .title{
        font-size: 1.5rem;
        color: black;
        text-align: left;
        margin-bottom: 100px;
        &.exptT{
          margin-bottom:50px;
        }
      }
      .container-form{
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        .input-form{
          width: 100%;
          text-align: left;
          padding: 20px 10px;
          border: 1px solid #f0eef2;
          color: black;
          margin-bottom:20px;
          border-radius:10px;
          font-size: 16px;
          ::-webkit-input-placeholder {
            color: #f0eef2;

          }
          &:focus{
            border: 1px solid #f0eef2;
          }
        }
        .error-message{
          color: red;
          margin-bottom: 20px;
          margin-top: 0px;
        }
        .button-reset{
          text-decoration: underline;
          cursor: pointer;
          color: black;
          font-size: 14px;
          margin-top:30px;
          margin-bottom: 30px;
        }
        .button-submit{
          background-color: #212b36;
          text-align: center;
          font-size: 16px;
          padding: 20px 10px;
          cursor: pointer;
          border-radius: 10px;
          border: 1px solid #212b36;
          width: 100%;
          color: #FFFFFF;
        }
      }
      .login-img-2{
        margin-top: 30px;
        width: 100%;
      }
    }
  }
}