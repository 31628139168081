.view--analytics{
  display: flex;
  flex-direction: column;
  padding: 10px;
  .container-analytics{
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.marginTop{
      margin-top: 15px;
    }
  }
}