.component--firststep-stepper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  .title-container{
    font-size: 15px;
    margin-bottom: 10px;
    color: #22286F;
  }
  .description{
    max-width: 80%;
    color: #22286F;
    margin-bottom: 20px;
    font-size: 13px;
  }
  .container-choice-color{
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .color-firststep{
      border-radius: 20px;
      width: calc(20% - 10px);
      height: 20px;
      margin-bottom: 10px;
      cursor: pointer;
      margin-right: 10px;
      background: #22286F;
      &:nth-child(5n + 5){
      }
      &.border{
        border: 1px solid #000;
      }
    }

  }
}