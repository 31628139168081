.view--chat{
  display: flex;
  flex-direction: column;
  padding: 20px;
  .title-view{
    font-size: 40px;
    color: black;
    margin-bottom: 30px;
  }
  .chat-container-view{
    border-radius: 30px;
    width: 100%;
    display: flex;
    height: 700px;
    .left-part{
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      background: black;
      .header-left-part{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        background: #212b36;
        .title-header{
          font-size: 20px;
          color: #FFFFFF;
        }
      }
      .seperate{
        height: 1px;
        width: 100%;
        background: #d1e7dd;
      }
      .container-list-chat-users{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        height: 700px;
        overflow-x: hidden;
        background: #212b36;
        .component-chat{
          background: transparent;
          padding: 10px;
          display: flex;
          margin-bottom: 20px;
          align-items: center;
          .title-name{
            margin-left: 10px;
            color: #FFFFFF;
            font-size: 15px;
            font-weight: 400;

          }
          &:hover{
            background: rgba(255,255,255,0.1);
            border-radius: 5px;
          }
        }
      }
    }
    .seperate-height{
      height: 100%;
      width: 1px;
      background: #d1e7dd;
    }
    .right-part{
      display: flex;
      flex-direction: column;
      width: 80%;
      height: 700px;
      border-radius: 10px;
      .header-chat{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        background: #212b36;
        .title-chat{
          font-size: 20px;
          color: #FFFFFF;
        }
      }
      .seperate{
        height: 1px;
        width: 100%;
        background: #d1e7dd;
      }
      .container-right-part{
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        .space-message{
          padding: 20px;
          background: #212b36;
          height: 551px;
          overflow-y: scroll;
          overflow-x: hidden;
        }
        .input-container{
          background: #212b36;
          display: flex;
          align-items: center;
          padding: 20px;

          input{
            width: 100%;
            padding: 10px 20px;
            background: #FFFFFF;
            color: #212b36;
            border-radius: 5px;
            margin-right: 10px;
            border: 1px solid #FFFFFF;
          }

          form {
            display: flex;
            align-items: center;
            width: 100%;
          }

          button {
            background: none;
            border: none;
            padding: 0;
            margin: 0;
            cursor: pointer;
            width: 30px;
          }

          .button-send{
            width: 30px;
          }
        }
      }
    }
  }
}
