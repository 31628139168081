.container-modal{
  padding:20px;
  display:flex;
  flex-direction:column;
  background-color:#fff;
  border-radius:10px;
  .header-modal{
    display:flex;
    align-items:center;
    justify-content: space-between;
    margin-bottom:30px;
    .title-header{
      font-size:16px;
      font-weight:bold;
      color: #22286F;
    }
    .button-close{
      width:30px;
      height:30px;
      cursor: pointer;
    }
  }
  .form-container{
    max-height: 500px;
    overflow-x: scroll;
    display:flex;
    flex-direction:column;
    .title-alone{
      font-size:12px;
      font-weight:500;
      color: #22286F;
      margin-bottom: 10px;
    }
    .container-input-modal{
      display:flex;
      flex-direction:column;
      margin-top: 10px;

      .title-input{
        font-size:12px;
        font-weight:500;
        color: #22286F;

        margin-bottom: 10px;
      }
      .input-container-modal{
        width: 100%;
        text-align: left;
        padding: 10px 5px;
        border: 2px solid #22286F;
        color: #22286F;
        background-color:transparent;
        font-size: 12px;
        margin-bottom:10px;
        border-radius:5px;
        ::-webkit-input-placeholder {
          color: #22286F;
        }
        &:focus{
          border: 1px solid #22286F;
        }
      }
    }
    .button-upload{
      background-color: #212b36;
      text-align: center;
      font-size: 16px;
      padding: 20px 10px;
      cursor: pointer;
      border-radius: 10px;
      margin-top: 20px;
      border: 1px solid #212b36;
      width: 100%;
      color: #FFFFFF;
    }
  }
}